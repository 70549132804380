/*
Buttons style
*/
.buttons{
    a{
        margin: 5px;
    }
}
.btn{
    border: 0px;  
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    @include border-radius(3px);
    font-family:$title-font;
    
    i{
        margin-left: 5px;
    }
}
.btn-lg{
    padding: 12px 25px;
}
.btn-xl{
    padding: 16px 25px;
}
.btn-link{
    color:$skincolor;
    &:hover{
        color:#999;
    }
}
.btn-default{
    background-color: #eee;
    color:#333;
    &:hover{
        background-color: $skincolor;
        color:#fff;
    }
}
.btn-skin{
    background-color:$skincolor;
    text-shadow: 0 1px rgba(0, 0, 0, 0.15);
    color:#fff;
    &:hover{
        background-color: #333;
        color:#fff;
    }
}
.btn-dark{
    background-color:#333;
    text-shadow: 0 1px rgba(0, 0, 0, 0.15);
    color:#fff;
    &:hover{
        background-color: $skincolor;
        color:#222;
    }
}
.btn-white{
    background-color:#fff;
    color:#333;
    &:hover{
        background-color: #f3f3f3;
        color:#333;
    }
}
.btn-border-dark{
    border: 2px solid #333;
    background-color: transparent;
    color:#333;
    &:hover{
        color:#fff;
        background-color: #333;
    }
}

.btn-border-skin{
    border: 2px solid $skincolor;
    background-color: transparent;
    color:$skincolor;
    &:hover{
        color:#fff;
        background-color: $skincolor;
    }
}
.btn-border-white{
    border: 2px solid #fff;
    background-color: transparent;
    color:#fff;
    &:hover{
        color:#333;
        background-color: #fff;
    }
}
