
/* 
   Sidebar widget
*/
.sidebar-widget{
    margin-bottom: 30px;
    .side-search{
        form{
            position: relative;
            button{
                background: transparent;
                border: 0px;
                position: absolute;
                top: 0;
                right: 0;
                width: 50px;
                height: 45px;
                line-height: 45px;
                text-align: center;
            }
        }
    }
}
.card-list{
    border:1px solid #e1e1e1;
    li{
        a{
            display: block;
            padding: 15px;
            border-bottom:1px solid #e1e1e1;
            span{
                color:#777;
                em{
                    display:inline-block;
                    margin-left:10px;
                    font-size: 13px;
                }
            }
            h4{
                margin: 0px;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
            }
            &:hover{
                background-color: #f5f5f5;
            }
        }
        &:last-child{
            a{
                border-bottom: 0px;
            }
        }
    }
}
.category-list{
    margin: 0;
    li {
        padding: 3px 0;
        border-bottom:1px dashed #eee;
        a{
            color:#999;
            &:before{
                font-family: 'Ionicons';
                content: "\f10b";
                margin-right: 10px;
            }
            &:hover{
                color:$skincolor;
            }
        }
    }
}