/*
association boxes
*/
.association{
  float:right;
  width:75px;
  padding:10px 20px;
}

.association img{
  width: 75px;
  padding: 10px 0 10px 0;
}

/*
card boxes
*/

.service-box{
    .service-thumb{
        position: relative;
        overflow: hidden;
    }
    .service-desc{
        padding: 15px;
        background:#fff;
        .border-width{
            margin: 5px 0;
        }
        p{
            margin-top: 10px;
        }
    }
}

.icon-box{
    .border-width{
        margin: 0px;
        margin-bottom: 10px;
    }
    i{
        color:  $skincolor;
        float: left;
        margin-right: 20px;
        width: 70px;
        height: 70px;
        line-height: 85px;
        @include border-radius(50%);
        border: 1px solid $skincolor;
        display: block;
        text-align: center;
        &:before{
            margin-left: 2px;
            font-size: 40px;
        }
    }
    .content{
        overflow: hidden;
        text-align: left;
    }
}

.prject-box{
   position:relative;
   overflow:hidden;
   display:block;
   img{
       @include transition(all 2s ease-in-out 0s);
       opacity: 1;
   }
    .project-overlay{
       position: absolute;
       width: 96%;
       height: 96%;
       left: 2%;
       top: 2%;
       background-color: rgba(0,0,0,0.4);
       opacity:0;
       @include transition(all 0.3s);
       h4{
           color:#fff;
           position: absolute;
           bottom: -100%;
           left: 20px;
            font-weight: 600;
            @include transition(all 0.4s);
           i{
               margin-left: 8px;
           }
       }
    }
    &:hover{
        .project-overlay{
            opacity: 1;
            h4{
                bottom: 20px;
            }
        }
        img{
            @include transform(scale(1.3));
            opacity: 0.7;
        }
    }
}
.support-info{
    .col-sm-4{
        text-align: center;
    }
    i{
        color: $skincolor;
        margin-bottom: 20px;
        font-size: 30px;
        display: block;
        width: 100%;
    }
}

.project-info{
    li{
        line-height: 40px;
        vertical-align:middle;
        margin:0;
        padding:0px;
        border-bottom:1px dashed #ddd;
        margin-top:3px;
        i{
            display: inline-block;
            width: 40px;
            height: 40px;
            text-align: center;
            background: $skincolor;
            font-size: 25px;
            line-height: 40px;
            margin-right: 20px;
        }
    }
}
.side-nav{
    li{
        margin: 0;
        padding: 0;
        a{
            display: block;
            padding: 10px 15px;
            background-color: #f5f5f5;
            font-family: $title-font;
            margin-bottom: 2px;
            @include border-radius(3px);

        }
        &.active a, a:hover{
            background-color: $skincolor;
            color:#fff;
        }
        &.active a{
              text-shadow: 0 1px rgba(0, 0, 0, 0.15);
        }
    }
}
