/*
master slider
*/
.title2 {
    font-size: 50px;
    color: #fff;
    line-height: 50px;
    font-weight: 800;
}

.sub-title2 {
    color: #fff;
    font-size: 14px;
}

.sub-title3 {
    color: #fff;
    font-size: 34px;
    font-weight: 300;
    text-transform: capitalize;
}

.btn1 {
    @include border-radius(4px);
    padding: 10px 25px;
    background-color: $skincolor;
    color: #fff;
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-weight: 700;
    text-shadow: 0 1px rgba(0, 0, 0, 0.15);
    @include transition(all 0.3s);
}
.btn1 i {
    margin-left: 5px;
}
.btn1:hover {
    color: #333;
    background-color: #ddd;
}

.btn2 {
    @include border-radius(4px);
    padding: 10px 25px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-weight: 700;
    @include transition(all 0.3s);
}
.btn2 i {
    margin-left: 5px;
}
.btn2:hover {
    color: #333;
    background-color: $skincolor;
}


/**carousel service slider**/
.service-slider{
    .item{

    }
    .owl-controls{
        position: absolute;
        width: auto;
        top: 0;
        margin-top: -50px;
        right: 0px;
        .owl-buttons div{
            @include border-radius(0px);
            background: transparent;
            font-size: 35px;
            color:$skincolor;
        }

    }
}


/**carousel project slider**/
.project-slider{
    position: relative;
    .owl-controls{
        position: absolute;
        width: auto;
        top: 50%;
        margin-top: -20px;
        left: 0px;
        width:100%;
        .owl-buttons div{
            @include border-radius(0px);
            background: #333;
            font-size: 35px;
            color:$skincolor;
        }
        .owl-prev{
            position: absolute;
            left: 0;
        }
        .owl-next{
            position: absolute;
            right: 0;
        }
    }
}

/**tesimonials**/
.testimonials{
    background-image: url(../images/pattern.png);
    background-repeat: repeat;
    padding: 60px 0;
    background-color:#222;
    .center-title h1{
        color:#fff;
    }
}
.testi-slider{
    padding: 0px 30px;
    i{
        color:$skincolor;
        font-size: 50px;
        display: block;
    }
    p{
        color:#fff;
        font-style: italic;
        margin: 20px 0;
        font-size: 20px;
        line-height: 35px;
    }
    h5{
        color:$skincolor;
    }
    .owl-controls{

        .owl-page span{
            @include border-radius(50%);
            background: transparent;
            border: 1px solid $skincolor;
        }
        .owl-page.active span{
            background-color: $skincolor;
        }
    }
}