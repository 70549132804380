/* 
    Basics
*/

body{
    font-family:$base-font;
    font-size:15px;
    line-height:26px;
    margin:0;
    background-color:#fff;
    color:#777;
    -webkit-font-smoothing:antialiased!important
}
a{
    @include transition(all 0.3s);
    color:$skincolor;
    &:hover{
        color:#aeaeae;
    }
}
button{
  @include transition(all 0.3s);
  &:focus{
      outline: 0 !important;
  }
}
a,a:hover,a:focus{
    text-decoration: none;
    outline: 0 !important;  
}
button:focus{
    outline: 0 !important;
}
.color_text{
    color:$skincolor;
}
h1,h2,h3,h4,h5,h6{
    margin-top: 0px;
    font-family: $title-font;
    color:#333;
    font-weight: 700;
}
blockquote{
    font-size: 14px;
    font-family:$title-font;
    font-weight:400;
    h5{
        margin-top: 10px;
        font-weight: 600;
        color: $skincolor;
    }
}
.full-wid{
    width: 100%;
}
/**spaces**/
.space10{
    height:10px;
}
.space20{
    height:20px;
}
.space30{
    height:30px;
}
.space40{
    height:40px;
}
.space50{
    height:50px;
}
.space60{
    height:60px;
}
.space70{
    height:70px;
}
.space80{
    height:80px;
}

/**margins**/
.margin-btm-10{
    margin-bottom: 10px;
}
.margin-btm-20{
    margin-bottom: 20px;
}
.margin-btm-30{
    margin-bottom: 30px;
}
.margin-btm-40{
    margin-bottom: 40px;
}
.margin-btm-50{
    margin-bottom: 50px;
}
.border-width{
    width: 60px;
    height: 3px;
    background: $skincolor;
    margin: 30px 0;
}
.gray-bg{
    background-color: #f5f5f5;
}
.color_text{
    color: $skincolor;
}
.center-align{
    margin: 0 auto;
    display: block;
}
.text_color{
    color:$skincolor;
}
.dropcap{
    &:first-letter{
        display: inline-block;
        content: "";
        padding: 5px 10px;
        background: $skincolor;
        margin-right: 5px;
        margin-bottom: 5px;
    }
}
.dropcap1{
    &:first-letter{
        display: inline-block;
        content: "";
        color: $skincolor;
        font-weight: 700;
        font-size: 20px;
        margin-right: 5px;
        margin-bottom: 5px;
    }
}
/**********back to top*************/
.scrollToTop {
  text-align: center;
  font-weight: bold;
  color: $skincolor;
  text-decoration: none;
  font-size: 30px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999999;
  display: none;
  opacity: 0.5;
  line-height: 0px;
 @include transition(all 0.3s);
}
.scrollToTop:hover {
  text-decoration: none;
  opacity: 1;
}

/**preloader**/
 div#preloader { 
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
    width: 100%;
    height: 100%;
    overflow: visible;
    background: #fff url('../images/loader.gif') no-repeat center center;
}
@media(min-width:768px){
    .vertical-align-child {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    flex-direction: row;
    -webkit-flex-direction: row;
}
}
@media(min-width:1200px){
    body.boxed{
        background-color: #e9e9e9;
    }
    .wrapper-boxed{
        width:1170px;
        margin: 0px auto;
        background: #fff;
        border-top: 3px solid $skincolor;
    }
}

/**pagination**/
.pagination{
    @include border-radius(0px);
}
    .pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 10px 22px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: $skincolor;
    text-decoration: none;
    background-color: #f5f5f5;
    border: 0px;
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    z-index: 3;
    color: #fff;
    cursor: default;
    background-color: $skincolor;
    border:0px;
}