/*
form-elements style
*/
.form-control,input,select,textarea{
    @include border-radius(0px);
    min-height: 45px;
    @include box-shadow(none);
    border: 2px solid #e1e1e1;
    &:focus{
        @include box-shadow(none);
        border-color: $skincolor;
    }
}