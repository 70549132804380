/* 
    Navbar css
*/
.navbar-default {
    background-color: #fff;
    border:0px;
    margin: 0px;
}
.navbar-default .navbar-nav>li>a {
    color: #333;
    font-family: $title-font;
    font-weight: 700;
    text-transform: uppercase;
    font-size:14px;
    &:hover{
        color:$skincolor;
        background-color: transparent;
    }
}
.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover,.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
    color:$skincolor;
    background-color: transparent;
    border-bottom-color: $skincolor;
}
.navbar-brand>img {
    display: block;
    margin-top: 3px;
}
/**search form**/
.search-toggle{
    position: relative;
}
.search-form{
    position: absolute;
    right: 0;
    width: 250px;
    top: 100%;
    padding: 5px;
    background-color: #e1e1e1;
    display: none;
    .form-control{
        border: 0px;
    }
}
@media(min-width:768px){
    .navbar-nav>li>a {
    padding-top: 20px;
    padding-bottom: 20px;
}
.navbar-brand > img {
    display: block;
    margin-top: 7px;
}
}