/*
Titles style
*/

.center-title{
     text-align: center;
    position: relative;
    h1{
        font-size: 25px;
        line-height: 25px;
        font-weight: 700;
         text-transform: uppercase;
    }
    .border-width{
        margin-top: 20px;
        
    }
}
.left-title{
    text-transform: capitalize;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
    position: relative;
    margin-bottom:30px;
    &:after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 60px;
        height: 3px;
        background: $skincolor;
    }
}