/*
Top bar style
*/
.top-bar{
    padding-top:10px;
    span{
        display: inline-block;
        margin-right: 10px;
        i{
            width: 20px;
            height: 20px;
            line-height: 20px;
            background: $skincolor;
            text-align: center;
            display: inline-block;
            @include border-radius(3px);
            margin-right: 5px;
            color:#fff;
        }
    }
    .list-inline{
        li{
            padding: 0px;
            a{
                width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                display: block;
                background: #f5f5f5;
                font-size: 20px;
                color:#999;
                @include border-radius(4px);
                &:hover{
                    color:$skincolor;
                    background: #333;
                }
            }
        }
    }
}

