@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,600italic,600,400italic,300italic,300);
@import url(https://fonts.googleapis.com/css?family=Roboto:700,500,900);
@import "_mixins.scss";
@import "_variables.scss";
@import "_base.scss";
@import "_buttons.scss";
@import "_titles.scss";
@import "_boxes.scss";
@import "_cta.scss";
@import "_topbar.scss";
@import "_navbar.scss"; 
@import "_dropdown.scss";
@import "_form-elements.scss";
@import "_slider.scss";
@import "_sidebar.scss";
@import "_tabs.scss";
@import "_news.scss";
@import "_staff.scss";
@import "_accordions.scss";
@import "_breadcrumb.scss";
@import "_footer.scss";


/**404**/
.error-digit{
    i{
        display: block;
        font-size: 100px;
        line-height: 100px;
        color: #ddd;
        margin-bottom: 30px;
    }
}


/**project filter**/

#grid .mix{
    display: none;
}
.project-filters{

    li{
        text-transform: uppercase;
        cursor:pointer;
        display: inline-block;
        margin: 3px;
        padding: 3px 12px;
        background: #f5f5f5;
        &.active{
            background: $skincolor;
            color:#fff;
        }
    }
}

/**social**/
.social{
    li{
        padding: 0px;
        a{
            margin: 5px;
            color:#aeaeae;
            font-size: 35px;
            &:hover{
                color: $skincolor;
            }
        }
    }
}

/**static hero**/
.hero-banner{
    position: relative;
    overflow: hidden;
    .hero-overlay{
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position:absolute;
        .hero-inner{
            width: 100%;
            height: 100%;
            display: table;
            position: relative;
            .hero-content{
                width: 100%;
                height: 100%;
                display: table-cell;
                vertical-align: middle;
                h1{
                    color:#fff;
                    font-weight: 500;
                    font-size: 60px;
                    line-height: 60px;
                    text-transform: capitalize;
                    text-shadow: 2px 2px 4px #000000;
                }
                p{
                    color:#fff;
                    text-transform: capitalize;
                    text-shadow: 2px 2px 4px #000000;
                }
            }
        }
    }
}


/****Responsive css**/
@media(max-width:767px){
    .hero-banner .hero-overlay .hero-inner .hero-content h1{
        font-size: 30px;
        line-height: 30px;
        text-shadow: 2px 2px 4px #000000;
    }
    .hero-banner .hero-overlay .hero-inner .hero-content{
        p{
            font-size: 13px;
            line-height: 22px;
            text-shadow: 2px 2px 4px #000000;
        }
    }
    .hero-banner .hero-overlay .hero-inner .hero-content .btn-xl{
        padding: 6px 12px;
    }
    .top-bar .text-right{
        text-align: left;
        margin-top: 20px;
    }
    .search-form {
    padding: 0px 15px;
    background-color: transparent;
    width:100%;
    }
}