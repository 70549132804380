/*
Blog style
*/

.post{
    .post-meta{
        h4{
            font-size: 24px;
            a{
                color:#222;
                &:hover{
                    color: $skincolor;
                }
            }
        }
        ul{
            li{
                a{
                    font-size: 12px;
                    color:#777;
                    &:hover{
                        color:$skincolor;
                    }
                }
            }
        }
    }
}
.blog-row hr{
    margin: 30px 0;
}
.comments{
    li:nth-child(even){
        padding:15px;
        background-color:#f5f5f5;
        margin:20px 0;
    }
    .thumb{
        float: left;
        margin-right: 20px;
        i{
            font-size: 40px;
        }
    }
    .content{
        overflow: hidden;
    }
    h5{
        text-transform: capitalize;
        font-weight: 700;
    }
    p{
        margin-bottom: 0px;
    }
}