/*
Dropdown css
*/
.dropdown-menu{
    @include box-shadow(none);
   min-width: 190px;
    background-color:#333;
    @include border-radius(0px);
    padding:0px;
    min-width:210px;
    border-color:#333;
    >li>a{
        padding: 10px 20px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        font-family: $title-font;
        color:#fff;
        &:hover{
            color:$skincolor;
            background-color: rgba(0,0,0,0.1);
        }
    }
    >li:last-child a{
        border-bottom: 0px;
    }
}
/**third level menu**/
.dropdown-submenu {
    position: relative;
   
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
  @include border-radius(0px);
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    float: right;
    content: "\f105";
    font-family: "FontAwesome";
    margin-top:2px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}

/**mega menu**/
.yamm .yamm-content{
    @media(max-width:969px){
        padding-left:20px;
        padding-right:20px;
    }
    h3{
        text-transform: uppercase;
        font-size: 13px;
        font-family: $title-font;
        margin-bottom: 20px;
    }
    .mega-vertical-nav{
        >li>a{
            padding: 7px 15px;
            color:#333;
            font-weight: 500;
            font-size: 13px;
            text-transform: capitalize;
           border-bottom: 1px solid #ddd;
           font-family: $title-font;
           &:hover{
               color:$skincolor;
           }
        }
        >li:last-child{
            a{
                border-bottom: 0px;
            }
        }
    }
}
.yamm .dropdown.yamm-fw .dropdown-menu.mega-menu {
    left: 0px;
    right: 0px;
}
@media(max-width:767px){
    .yamm .yamm-content h3{
        color:#fff;
    }
    .yamm .yamm-content .mega-vertical-nav > li > a {
        border-bottom-color: rgba(255,255,255,0.1);
        color:#eee;
        &:hover{
            color:#fff;
            background-color: rgba(0,0,0,0.1);
        }
    }
    .dropdown-menu>li>a{
       border-bottom-color: rgba(255,255,255,0.1);
        padding:8px 20px;
        
        &:hover{
            color:#fff;
            background-color: rgba(0,0,0,0.1) !important;
        }  
    }
    .mega-vertical-nav{
        padding-bottom: 20px;
    }
}